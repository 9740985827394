var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body mobile"},[_c('section',{staticClass:"header notice"},[_c('div',{staticClass:"flexH width"},[_c('a',{staticClass:"icon iconButton third",on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-left","size":"lg"}})],1)]),_c('span',{staticClass:"fL nowrap font-bold font-white"},[_vm._v("交易明細")]),_c('div',{staticClass:"flexH width right"})]),_c('section',{staticClass:"main notice"},[(_vm.datas.length !== 0 && _vm.status === 1)?_c('div',{staticClass:"records flexV width padding mt-3"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_c('div',{staticClass:"text-center flex gap-6 item-center py-4"},[_c('img',{attrs:{"width":"160","height":"160","src":require("@/assets/icon/empty-notification.svg")}}),_c('div',{staticClass:"fL font-medium cNeutral500 text-center"},[_vm._v(" 很抱歉，目前尚未有可兌換的折抵券 ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"primary mr-3"},[_c('span',{staticClass:"fS font-normal cNeutra500 font-sm flex items-center"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/icon/coin.svg"),"alt":""}}),_c('div',{staticStyle:{"font-size":"24px"}},[_vm._v("1000")])])]),_c('div',{staticClass:"primary"},[_c('span',{staticClass:"fS font-normal cNeutra500 font-sm flex items-center"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/icon/coin.svg"),"alt":""}}),_c('div',{staticStyle:{"font-size":"24px"}},[_vm._v("200")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("交易型態")]),_c('div',[_vm._v("消費")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("交易時間")]),_c('div',[_vm._v("2025/01/01 12:00:59")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("消費者姓名")]),_c('div',[_vm._v("王＊明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("消費者電話")]),_c('div',[_vm._v("0987***321")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("交易特店")]),_c('div',[_vm._v("商家名稱")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("交易編號")]),_c('div',[_vm._v("AS1234567890")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("支付方式")]),_c('div',[_vm._v("信用卡")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v("交易狀態")]),_c('div',[_vm._v("已付款")])])
}]

export { render, staticRenderFns }