<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">歷史請款紀錄</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <div class="records flexV width padding">
        <div v-for="(item, index) in data" :key="'item' + index">
          <a
            class="record flexH width margin relative shadow-md"
            @click.stop="detail(item)"
          >
            <div class="flexV width gap-1 text-gray-500 fS">
              <div class="flex">
                <div style="width: 130px">雲林幣請款數量</div>
                <div>{{ item.amount }}</div>
              </div>

              <div class="flex">
                <div style="width: 130px">雲林幣兌換台幣</div>
                <div>{{ item.currency }}</div>
              </div>

              <div class="flex">
                <div style="width: 130px">批號</div>
                <div>{{ item.number }}</div>
              </div>

              <div class="flex">
                <div style="width: 130px">請款紀錄建立日</div>
                <div>{{ item.recordDate }}</div>
              </div>

              <div class="flex">
                <div style="width: 130px">請款日</div>
                <div>{{ item.paymentDate }}</div>
              </div>

              <div class="flex">
                <div style="width: 130px">撥款日</div>
                <div>{{ item.grantDate }}</div>
              </div>
            </div>
          </a>
          <div class="lineH"></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      latestReadAt: 0,
      status: 0,
      data: [
        {
          amount: '180,000 枚',
          currency: "18,000 元",
          number: "M202250101 - b36ad17401998",
          recordDate: '2024/12/01',
          paymentDate: '2024/12/03',
          grantDate: '2024/12/25',
        },
        {
          amount: '180,000 枚',
          currency: "18,000 元",
          number: "M202250101 - b36ad17401998",
          recordDate: '2024/11/03',
          paymentDate: '2024/11/05',
          grantDate: '2024/11/23',
        },
        {
          amount: '180,000 枚',
          currency: "18,000 元",
          number: "M202250101 - b36ad17401998",
          recordDate: '2024/12/01',
          paymentDate: '2024/12/03',
          grantDate: '2024/12/25',
        },
        {
          amount: '180,000 枚',
          currency: "18,000 元",
          number: "M202250101 - b36ad17401998",
          recordDate: '2024/11/03',
          paymentDate: '2024/11/05',
          grantDate: '2024/11/23',
        },
        {
          amount: '180,000 枚',
          currency: "18,000 元",
          number: "M202250101 - b36ad17401998",
          recordDate: '2024/11/03',
          paymentDate: '2024/11/05',
          grantDate: '2024/11/23',
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    add() {
      this.$router.push("/digital-card/add");
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 16px !important;
}

button {
  color: white;
  border-radius: 20px !important;
  height: 51px !important;
}

img {
  width: 24 !important;
  height: 24px !important;
}
</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
